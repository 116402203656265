import cn from 'classnames'
import React, { FC } from 'react'

export enum TextSizes {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs',
}

export enum Colors {
  purple = 'purple',
}

export type TypographyProps = {
  size?: TextSizes
  title?: string
  className?: string
  color?: Colors
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'strong' | 'span'
}

const Typography: FC<Pick<React.HTMLProps<HTMLElement>, 'onClick'> & TypographyProps> = ({
  variant,
  title,
  size,
  children,
  className,
  color,
  onClick,
}) => {
  const classProp = cn('typography', {
    [`typography-${size}`]: !!size,
    [`${className}`]: !!className,
    [`typography--color-${color}`]: !!color,
  })
  return React.createElement(variant || 'div', { className: classProp, onClick }, title || children)
}

export default Typography
